
$ruler: 16px;
$color-base: #282728;
$color-bg: #32373c;
$color-shadow: #000000;
$color-white: #ffffff;
$color-point: #ff9100;
$color-light: #f06d45;
$color-pin: #757b81;
$color-body: #EBECF0;

#root{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    overflow-y: scroll;
}
.select_box{
    
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    select{
        
        width:350px;
        color: #000;
       
    }
}
.totalBox{
    margin: 0 auto;
    margin-top: -20px;
    margin-bottom: 10px;
    width: 880px;
    display: flex;
    flex-direction: column;
   
    background-color: #EBECF0;
    border-radius: 10px;
    padding: 10px;
    .total{
        display: flex;
        p{
            text-align: center;
            font-size: 20px;
            margin: 10px;
            margin-bottom: 0px;
            text-shadow: none;
            color: #000;
    
            
        }
   
    }
    .total2{
        display: flex;
        p{
            
            font-size: 12px;
            margin: 10px;
            margin-top: 5px;
            margin-bottom: 10px;
            text-shadow: none;
            color: #333;
    
            
        }
    }
    

    
}
.box_top{
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    width:650px;
    flex-wrap: wrap;
    justify-content: center;
}
#big_btn{
    margin: 0 auto;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    height:120px;
    p{
        font-size: 14px;
        text-shadow: none;
    }
}
.shdowIcon{
    display: block;
    margin: 0 auto;
    height:180px;
    margin-top: 20px;
    filter: drop-shadow(1px 1px 1px #000);
}
.setting_box{
    width:850px;
    
    padding: 20px;
    display: flex;
}

.top_btn{
    cursor: pointer;
    text-decoration: underline;
}

#heat_map{

    position: absolute;
    z-index: 200;
    mix-blend-mode: hard-light; 
    //mix-blend-mode: soft-light; 
    //mix-blend-mode: unset; 
    opacity: 0.4;

}
.loading{
    position: fixed;
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    background:rgba(0,0,0,0.8);
    top:0;
    left:0;
    img{
        width:50px;
    }
}
.arrow{
    position: absolute;
    z-index: 201;
    width:1000px;
    height:1040px;
    img{
        position: absolute;
    z-index: 302;
    }
    .a2_0{
        left:340px;
        top:325px;
    }
    .a2_90{
        left:302px;
        top:355px;       
    }
    .a2_180{
        left:220px;
        top:310px;       
    }

    .a2_270{
        left:302px;
        top:245px;       
    }

    .a3_0{
        left:170px;
        top:490px;
    }
    .a3_90{
        left:280px;
        top:455px;       
    }
    .a3_180{
        left:280px;
        top:505px;        
    }

    .a3_270{
        left:240px;
        top:560px;     
    }


    .a4_0{
        left:450px;
        top:610px;
    }
    .a4_90{
        left:510px;
        top:550px;    
    }
    .a4_180{
        left:520px;
        top:610px;    
    }

    .a4_270{
        left:500px;
        top:620px;     
    }


    .a5_0{
        left:610px;
        top:510px;
    }
    .a5_90{
        left:575px;
        top:530px;
    }
    .a5_180{
        left:500px;
        top:510px;    
    }

    .a5_270{
        left:580px;
        top:450px;     
    }

    .a6_0{
        left:250px;
        top:720px;
    }
    .a6_90{
        left:250px;
        top:720px;
    }
    .a6_180{
        left:250px;
        top:720px; 
    }

    .a6_270{
        left:250px;
        top:720px; 
    }


    .a7_0{
        left:360px;
        top:685px;
    }
    .a7_90{
        left:400px;
        top:665px;
    }
    .a7_180{
        left:490px;
        top:730px;
    }

    .a7_270{
        left:400px;
        top:705px;
    }
}
#event{
    display:none;
}
.contents{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    

}
#state{
    font-weight: bold;
    font-size: 20px;
  
}
#user_name{
    
    margin-right: 20px;
}
.tenki_set{
    border-radius: 20px;
    display: flex;
    align-items:center;
    width:940px;
    
    margin-top: 10px;
    border: 2px solid #FFF;
    
    .my-checkbox {
        margin-left: 20px;
        width:80px;
    }
}
.eventSet{

    margin-top: 10px;
    
    display: flex;
    flex-direction: column;
    width:940px;
    .my-checkbox {
        width:130px;
    }
    .borders{
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-wrap:wrap;
        border: 2px solid #FFF;
        border-radius: 20px;
    }

}
.map{
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  
    .level_A{
        position: absolute;
        left:10px;
        top:250px;
        z-index: 201;
    }
    .level_B{
        position: absolute;
        left:280px;
        top:300px;
        z-index: 201;
        width:7%;
    }

    .level_C{
        position: absolute;
        left:230px;
        top:510px;
        z-index: 201;
        width:7%;
    }
    .level_D{
        position: absolute;
        left:460px;
        top:550px;
        z-index: 201;
        width:7%;
    }
    .level_E{
        position: absolute;
        left:570px;
        top:450px;
        z-index: 201;
        width:7%;
    }
    .level_F{
        position: absolute;
        left:220px;
        top:780px;
        z-index: 201;
        width:7%;
    }

    .level_G{
        position: absolute;
        left:430px;
        top:700px;
        z-index: 201;
        width:7%;
    }
    .name{
        position: absolute;
        z-index: 201;
        left:0px;
        top:0px;
        
    }
    .konzatsu{
        position: absolute;
        z-index: 201;
        left:870px;
        top:10px;
        border-radius: 20px;
        padding: 20px;
        background-color:rgba(0,0,0,0.8);
    }

    .konzatsu2{
        position: absolute;
        left:520px;
        top:390px;
        
    }
}

.header{
    width:1000px;
    height:80px;
    display: flex;
    align-items: center;
    background-color: #000000;
    
    .title_box{
        width:400px;
        display: flex;
        align-items: center;
        P{
            font-weight: bold;
            font-size: 20px;
        }
        img{
            width:25px;
        }
    }
    .header_btn{
        width:600px;
        height:40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        .btn{

            width:180px;
            height: 40px;
            padding:5px;
            margin: 0px;
        }

    }
}
.visible{
    display:flex;
}
  .invisible{
    display:none;
}

.hiduke_box{
    position: absolute;

    bottom: 10px;

    width:1000px;
    height:50px;
    z-index: 10;
    display:flex;
  justify-content: center;
  align-items:center;
    .day{
        text-align: center;
        width:750px;
        height:50px;
        border-radius: 20px;
        background-color:rgba(0,0,0,0.75);
    }

}
.graph{
    display:flex;
    justify-content: center;
    align-items:center; 
    width:1000px;
    
    background-color: #FFF;
    margin-bottom: 10px;
}

.graph2{
    display:flex;
    justify-content: center;
    align-items:center; 
    width:1000px;
    
    background-color: #FFF;
    margin-bottom: 10px;
}
.navi{
    margin: 0 auto;
    width:100%;
    height:80px;
    background-color:$color-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -1px -1px 1px $color-white,  5px 5px 20px $color-shadow;
    .navi_box{
        width:760px;
        height:80px;
        display: flex;
        align-items: center;
        img{
            width:250px;
        }

        h1{
            text-align: left;
        }
        
    }
    .navi_right{
       
        margin-right: 0;
        height:80px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
       
    }

}
#yobo{
    width:800px;
}
.profileBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
   
}
.profile_text{
    width:850px;
    height:350px;
    border-radius: 10px;
    padding:10px;
    margin-top: -10px;
}

.eventTitle{
    margin-top: 20px;
    font-weight: bold;
}
.eventBox{
    overflow-y: scroll;
    width:850px;
    height:350px;
    margin-bottom: 50px;
}
.table_event{
    table-layout: fixed;
    width:850px;
  
    
 
    .item{
        background-color: #444444;
        //指マーク
        cursor: pointer;
    }
    //マウスオーバー時の背景色
    .item:hover{
        background-color: #828282;
    }

    th{
        font-size: 12px;
        font-weight: bold;
        padding: 10px;
        border-bottom: 1px solid #FFF;
    }
    td{
        font-size: 12px;
        padding: 10px;
        border-bottom: 1px solid #FFF;
    }
    #eventLink{
        cursor: pointer;
      
    }
    #ctg{
        width:100px;
        text-align: center;
    
    }
    #day{
        width:150px;
        text-align: center;
    
    }
     #spot{
        width:200px;
        text-align: center;
    
    }

}

.table_main{
    table-layout: fixed;
    width:850px;
    textarea{
        width:250px;
        height:80px;
        border-radius: 5px;
    }
    th{
        font-size: 14px;
        font-weight: bold;
        padding: 10px;
        border-bottom: 1px solid #FFF;
    }
    td{
        font-size: 14px;
        padding: 10px;
        border-bottom: 1px solid #FFF;
    }
    .no{
        width:50px;
        text-align: center;
    }
    .day_th{
        width:50px;
        text-align: center;
    }
    .type_th{
        width:20px;
        text-align: center;
    }
    .q_th{
        width:220px;
        text-align: center;
    }
    .a_th{
        width:220px;
        text-align: center;
    }
    .state_th{
        width:20px;
        text-align: center;
    }
    .btn_th{
        width:50px;
        text-align: center;
    }
}
.userTable{
    width:900px;
    text-align: center;
    font-size: 16px;
    margin-top: 80px;

}

#btn_mini{
    width:30px;
    height:30px;
    margin: 0 auto;
    border-radius: 50%;
    padding: 0px;
}
.logins{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#login_set{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .timeline_name{
        width:180px;
        height:60px;
        background-color:$color-base ;
        color: #FFF;
      }
      .timeline_name2{
        font-size: 14px;
        width:250px;
        height:60px;
        background-color:$color-base ;
        color: #FFF;
        margin: 10px;
      }
    }
.login_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100%;
  }
  h1{
    width: 100%;
    text-align: center;
    text-shadow: 2px 2px 2px #000000;
    font-size: 24px;
    margin-top: 30px;
}
.name{
    margin: 0 auto;
}
#login_box{
    width:620px;
   
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

    .btn{
        margin: 10px;
        width:200px;
    }



    p{
        margin-top:0px;
        margin-bottom:0px;
        width: 100%;
        text-align: center;
        
    }
    #input{
      margin: 10px;
      width:320px;
    }
}

.eventDay_set{
    display: flex;
}
.projectBox2{
    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        #input{
            width:780px;
        }
    .eventDitale{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    margin: 20px;
    .borders{
        width:750px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-wrap:wrap;
        border: 2px solid #FFF;
        border-radius: 20px;
        label{
            width:150px;
        }
    }

}
#setting{
    width:100px;
    margin: 5px;
    
}
#setting2{
    width:60px;
    padding:5px;
    margin: 5px;
}
.img_btn{
    display: flex;
    align-items: center;
    justify-content: center;
     
    width:100%;

}
#setting3{
    margin: 0 auto;
    width:300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    padding:5px;
}

#setting4{
    margin: 0 auto;
    width:200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding:5px;
}

.white_bg{
    width:1000px;
    height:250px;
    margin-bottom: 30px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .title_box{
        display: flex;
        align-items: center;
        justify-content: center;
        width:200px;
        height:250px;
        .title_set{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #000000;
            text-shadow: none;
            #title{
                margin:0;
                font-size: 14pt;
            }
            #num{
                margin: 0;
                font-size: 44pt;
                font-weight: bold;
            }

        }
    }

    .ditail_box{
        display: flex;
        align-items:flex-start;
        flex-direction: column;
        width:200px;
        height:250px;
        margin-left: 50px;
       
        color: #000000;
        text-shadow: none;
        h2 {
            font-size: 14pt;
            margin-bottom: 0.2rem;
            padding: 0.3rem;
            border-left: 10px solid;

            font-weight: bold;
            
        }
        .row{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
            margin-bottom: 0px;
            height:20px;
            
            .box{
                width:20px;
                height:20px;
                background-color: #32373c;
            }
            #men{
                background-color: #1E90FF;
            }
            #women{
                background-color: #FF1493;
            }
            #y10{
                background-color: #DC143C;
            }
            #y20{
                background-color: #FF4500
            }
            #y40{
                background-color: #FFD700;
            }
            #y60{
                background-color: #00CED1;
            }


            p{
                margin: 2px;
                font-size: 10pt;
                
            }

        }

    }

}
.login_set{
    display: flex;
    align-items: center;
    justify-content: center;
    width:80px;
    margin: 0 auto;
   
    #login{
        margin: 0 auto;
        text-align: center;
    }

}

.shdowLock{
    display: block;
    
    margin: 0 auto;
    
    filter: drop-shadow(1px 1px 1px #000);
}


.Calendar_block{
    width:100%;
    display: flex;
    flex-direction: column;
   
    justify-content: center;
    align-items: center;

   
    //background-color: #1384bf;
    .day_block{
      width:calc(100% - 40px);
      
      //background-color: #BABECC;
     
      padding: 20px;
      p{
        color: #000;
        font-weight:normal;
        margin: 0;

      }
      .day_main{
        width:100%;
        height:120px;
        
        background-color: #6983a0;
        border-radius:10px;
        box-shadow: 3px 3px 5px #373737 inset;

      }
      .day_scroll{
        position: relative;
        width:100%;
        height:140px;
        overflow-x: scroll;
        overflow-y: hidden;
        
        display: flex;
       

       
       
        .day_item{
          flex: none;
          margin: 5px;
          margin-top: 10px;
          width:100px;
          height:100px;
          background-color: #FFF;
          display:flex;
          align-items: center;
          justify-content: center;
          img{
            max-width: 90px;
            max-height: 90px;
          }
        }
      }
    

    }

  }



.cal_day::-webkit-calendar-picker-indicator,.cal_time::-webkit-calendar-picker-indicator {
    background-color: #FFF;
    padding: 5px;
    border-radius: 5px;
    box-shadow:  5px 5px 3px $color-shadow;
    cursor:pointer;
    
  }
  
  
  .cal_day,.cal_time{
    box-shadow: inset 1px 1px 2px #000, inset -1px -1px 2px #FFF;
  
  }
  
  #cal_day1,#cal_day2,#cal_day3,#cal_day4{
    width:160px;
    height:60px;
   
    
  
  }
  #cal_time1,#cal_time2,#cal_time3,#cal_time4{
    width:120px;
    height:20px;
   
  
  }

#cal_day11{
    width:360px;
    height:60px;
}
  

  ///////////////////////////////////////////////
.cal_form{
    width:850px; 
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(209, 209, 209);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items:center;
    
  
    margin: 5px;

    p{
      color: #000;
      text-shadow: none;
    }
    .timeline_name{
      width:280px;
      height:40px;
      padding: 10px;
      padding-left: 20px;
      background-color:$color-base ;
      color: #FFF;
    }
  }

  select{
    padding:20px;
    margin: 20px 0px;
    width:450px;
    height:40px;
    font-size: 10pt;
    border-radius: 20px;
    cursor: pointer;
  
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: $color-body;
    background-image: none;
    -webkit-appearance: none;
    appearance: none;

    background-image: url(../img/arrow2.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 26px 26px;
    
    //box-shadow: 4px 4px 2px #FFF inset,  6px 6px 6px $color-shadow ; 
    box-shadow:  inset 2px 2px 3px $color-shadow, inset -1px -1px 2px $color-white;
    
  }

  .bar{
    width:900px;
    
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color:#FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    canvas{
        height:450px;
        padding: 20px;
      
      }
  }

  .row{
    width:900px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color:#FFF;
    border-radius: 10px;
    h1{
        font-size: 18px;
        text-shadow: none;
        color: #000;

    }
  }
  #row{
    flex-direction: column;
    padding: 30px;
    padding-top: 5px;
    
    .report{
        text-align: left;
        font-size: 14px;
        text-shadow: none;
        color: #000;
    }
  }
  .en{
    width:380px;
    margin: 0 auto;
  }
  .dumy_box{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  .dumy_box2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    img{
        margin-bottom: 20px;
    }
  }
  .dumy{
    width:900px;
    margin: 0 auto;
   
  }
  .date{
    display: flex;
    flex-direction: column;
    width:350px;
    font-size: 20px;
  }

  .dumy2{
    width:500px;
    margin: 0 auto;

   
  }
  .reportTitle{
    font-size: 18px;
  }

  .user_block{
    width:900px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

#cal_select{
    width:300px;
    margin: 5px;
    color: #000;
    
}
.img_box{
    width: 850px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .photo{
        width:200px;
        padding: 20px;
        
    }
    p{
        font-size: 14px;

    }
    .thum{
        width:200px;
    }   

}
