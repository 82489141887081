$ruler: 16px;
$color-base: #282728;
$color-bg: #32373c;
$color-shadow: #000000;
$color-white: #ffffff;
$color-point: #ff9100;
$color-light: #f06d45;
$color-pin: #757b81;
html{
    height:100%;
    width:100%;
    overflow-y: scroll;
    //overflow: hidden  !important;
}

.original-gradient {
  height: 200px;
  width: 90%;
  background-image: linear-gradient(90deg, rgba(186, 29, 0, 1), rgba(220, 72, 16, 1));
}
.block{
  background-color: $color-bg;
  box-shadow: -1px -1px 1px $color-pin,  5px 5px 20px $color-shadow;
}
body{
    margin: 0;
    width:100%;
    height:100%;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.2px;
    font-size: $ruler;
    background-color:$color-base;
    color:$color-white;
    text-shadow: 1px 1px 0 $color-shadow;
   
   
}

.btn {
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: 20px;
    padding: $ruler;
    background-image: linear-gradient(120deg, rgba(186, 29, 0, 1), rgba(220, 72, 16, 1));
    text-shadow: 1px 1px 0 $color-white;
    margin: 0 auto;
    color:#61677C;
    font-weight:900;
    box-shadow: -1px -1px 1px $color-light,  5px 5px 20px $color-shadow;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
   
    
    &:hover {
      box-shadow: -2px -2px 5px $color-light, 2px 2px 5px $color-shadow;
    }
    
    &:active {
      box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-light;
    }
    
    .icon {
      margin-right: $ruler/2;
    }
    
    &.unit {
      border-radius: $ruler/2;
      line-height: 0;
      width: $ruler*3;
      height: $ruler*3;
      display:inline-flex;
      justify-content: center;
      align-items:center;
      margin: 0 $ruler/2;
      font-size: $ruler*1.2;
      
      .icon {
        margin-right: 0; 
      }
    }
}



.btn, input {
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: 20px;
    padding: $ruler;
    background-color:$color-point;
    color:$color-white;
    text-shadow: 1px 1px 0 $color-shadow;
    margin: 0 auto;
  }
  input {
    background-color:$color-base;

    color:$color-white;
    text-shadow: 1px 1px 0 $color-shadow;
    box-shadow:  inset 2px 2px 5px $color-shadow, inset -2px -2px 2px $color-pin;
   
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;

    &:focus {
      box-shadow:  inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-pin;
    }
  }
  .my-checkbox {
    display: block;
    position: relative;
    margin: 1em 0;
    padding-left: 28px;
    cursor: pointer;
    user-select: none; /* テキストの選択を防ぐ */
   
  }

  #cal_day::-webkit-calendar-picker-indicator,#cal_time::-webkit-calendar-picker-indicator {
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    box-shadow:  5px 5px 3px $color-shadow;
    
  }
  
  /* inputは非表示にする */
  .my-checkbox input {
    display: none;
  }
  
  /* □ */
  .checkmark {
    position: absolute;
    top: 0; /* 上からの位置 */
    left: 0;
    height: 22px; /* 大きさ */
    width: 22px; /* 大きさ */
    border: solid 2px #d4dae2; /* 線 */
    border-radius: 4px;
    box-sizing: border-box;
  }
  /* ✓ */
  .checkmark:after {
    content: "";
    position: absolute;
    left: 2px; /* チェックの位置 */
    top: -2px; /* チェックの位置 */
    width: 10px; /* チェックの大きさ */
    height: 10px; /* チェックの大きさ */
    border: solid rgb(178, 16, 16);
    border-width: 0 5px 5px 0;
    transform: rotate(45deg);
    opacity: 0; /* 透明にしておく */
  }
  
  /* チェックが入ったときの□ */
  .my-checkbox input:checked + .checkmark {
    background: #2e80ff; /* チェック時の色 */
    border-color: #2e80ff; /* チェック時の色 */
  }
  
  /* チェックが入ったときの✓ */
  .my-checkbox input:checked + .checkmark:after {
    opacity: 1; /* 透明を解除 */
  }

  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-text-fill-color:$color-white;
}


  form {
    padding: $ruler;
    width: $ruler*20;
    margin: 0 auto;
  }

  .list{
    counter-reset:list;
    list-style-type:none;
    padding:0;
    li{
        position:relative;
        display: flex;
        align-items: center;
        margin: 15px 0 15px 0px;
        padding-left:50px;
        font-weight: bold;
        font-size:16px;
        line-height: 40px;
        border: solid 1px #ffffff;
        border-radius:20px;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        -ms-transition: 0.3s;
        transition: 0.3s;
        
    }
    li:before{
        counter-increment: list;
        content: counter(list);
        position: absolute;
        left: 0px;
        width: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        line-height:40px;
        background: #c73210;
        border-radius: 50%;
        top: 50%;
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }

     

      

}
.centerBox{
  width:100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items:center;
}
.popup{
  
  position: relative;
  width:600px;
  border-radius: 20px;
  background-color: $color-bg;
  box-shadow: -1px -1px 1px $color-pin,  5px 5px 20px $color-shadow;
}
#maru_btn{
  position: absolute;
  left:calc(100% - 40px);
  top:-20px;
  width:30px;
  height:30px;
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items:center;
}

#maru_btn2{
  
  width:30px;
  height:30px;
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items:center;
}

#new_btn{
  position: absolute;
  left:calc(50% - 40px);
  width:80px;
  height:80px;
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items:center;
}

#del_btn{
  position: absolute;
  display:flex;
  justify-content: center;
  align-items:center;
  right:2px;
  width:80px;
  height:35px;
  padding: 0;
  margin: 0;
 

}
#edit_btn{
  position: absolute;
  display:flex;
  justify-content: center;
  align-items:center;
  right:92px;
  width:80px;
  height:35px;
  padding: 0;
  margin: 0;
 

}
  

.centerBox{
  width:100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items:center;
  margin: 30px;
}

.popup{
  position: relative;
  width:900px;
  border-radius: 20px;
  background-color: $color-bg;
  box-shadow: -1px -1px 1px $color-pin,  5px 5px 20px $color-shadow;
  
}

.projectBox2{
  margin: 30px;
  display: flex; 
  flex-direction: column;
  align-items: center;
  input{
      margin: 10px;
  }
  .btn{

      width:180px;
      height: 40px;
      padding:5px;
      margin: 10px;
  }

}


.btn_blue {
  border: 0;
  outline: 0;
  font-size: $ruler;
  border-radius: 20px;
  padding: $ruler;
  background-image: linear-gradient(120deg, rgba(186, 29, 0, 1), rgba(220, 72, 16, 1));
  text-shadow: 1px 1px 0 #000000;
  margin: 0 auto;
  color:#FFF;
  font-weight:900;
  box-shadow: -1px -1px 1px $color-light,  5px 5px 20px $color-shadow;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

 
  
  &:hover {
    box-shadow: -2px -2px 5px $color-light, 2px 2px 5px $color-shadow;
  }
  
  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-light;
  }
  
  .icon {
    margin-right: $ruler/2;
  }
  
  &.unit {
    border-radius: $ruler/2;
    line-height: 0;
    width: $ruler*3;
    height: $ruler*3;
    display:inline-flex;
    justify-content: center;
    align-items:center;
    margin: 0 $ruler/2;
    font-size: $ruler*1.2;
    
    .icon {
      margin-right: 0; 
    }
  }
}